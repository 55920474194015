<template>
  <div class="home">
    <div class="info" v-if="isShowGuaInfo">
      <iframe
        :src="
          'https://gm.yjdb.com/order/pdf?file=' +
          guaInfo.file +
          '&timestamp=' +
          parseInt(new Date().getTime() / 1000)
        "
      ></iframe>
    </div>
    <template v-else>
      <img class="cg-img" :src="require('../assets/cg.gif')" />
      <div class="title" v-if="guaInfo">{{ guaInfo.title }}</div>
      <div class="code">当前吃瓜号: {{ orderid }}</div>
      <div class="input">
        <input v-model="code" type="text" placeholder="请输入密码查看" />
      </div>
      <div class="get-btn" @click="showQrcode">点击获取密码</div>
      <div class="more-btn" @click="jumpIndex">查看更多瓜</div>
      <div class="qrcode" v-if="isShowQrcode">
        <div class="qrcode-help">
          在微信中长按或者扫描下面二维码<br />进入小程序中领取密码
        </div>
        <img :src="'https://gm.yjdb.com/order/qrcode?orderid=' + orderid" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      guaid: this.$route.query.guaid,
      isShowQrcode: false,
      isShowGuaInfo: false,
      orderid: "",
      code: "",
      guaInfo: null,
    };
  },
  watch: {
    code: function (newVal) {
      let _that = this;
      console.log(newVal);
      if (newVal.length == 4) {
        _that.guaGet([
          _that.orderid,
          _that.guaid,
          _that.code,
          function (err, data) {
            console.log(err, data);
            if (!err && !data.rtn && data.data) {
              _that.guaInfo = data.data;
              //   _that.isShowGuaInfo = true;
              let url =
                "/order/file/" +
                _that.guaInfo.file +
                "?timestamp=" +
                parseInt(new Date().getTime() / 1000);
              window.location.href = url;
            } else {
              _that.$message({
                message: "密码错误",
                type: "error",
              });
            }
          },
        ]);
      }
    },
  },
  methods: {
    ...mapActions(["guaGet", "orderAdd", "guaInfoGet"]),
    showQrcode: function () {
      this.isShowQrcode = true;
    },
    jumpIndex: function () {
      let _that = this;
      _that.$router.replace({
        path: "/",
      });
    },
  },
  mounted() {
    let _that = this;
    if (!_that.guaid) {
      _that.$router.replace({
        path: "/",
      });
    }
    _that.guaInfoGet([
      _that.guaid,
      function (err, data) {
        if (!err && !data.rtn && data.data) {
          _that.guaInfo = data.data;
        } else {
          _that.$router.replace({
            path: "/",
          });
        }
      },
    ]);
    _that.orderid = _that.$common.getLocalOrderId(_that.guaid);
    if (!_that.orderid) {
      _that.orderAdd([
        _that.guaid,
        function (err, data) {
          console.log(err, data);
          if (data && data.data && data.data.orderid) {
            _that.orderid = data.data.orderid;
            _that.$common.setLocalOrderId(_that.guaid, data.data.orderid);
          }
        },
      ]);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.home {
  text-align: center;
  padding-top: 1px;
  .cg-img {
    margin-top: 40px;
    width: 60px;
  }

  .title {
    font-size: 16px;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: bolder;
    width: 80%;
  }

  .code {
    font-size: 14px;
    margin-top: 10px;
  }
  .input {
    background: #f7f7f7;
    width: 200px;
    height: 30px;
    margin: 0 auto;
    margin-top: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    input {
      width: 200px;
      height: 30px;
      font-size: 18px;
      color: #666666;
      background: transparent;
      text-align: center;
    }
  }

  .get-btn {
    background-color: #07c160;
    width: 150px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 16px;
  }

  .more-btn {
    background-color: #aaaaaa;
    width: 150px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 16px;
  }

  .qrcode {
    width: 280px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 16px;
    color: red;
    line-height: 30px;
    img {
      margin-top: 20px;
      width: 80%;
    }
  }

  .info {
    // margin-top: 30px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
