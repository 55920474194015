import axios from "axios";

let gmApi = "https://gm.yjdb.com";
// gmApi = "";
const sendGmGet = function (path, data, cb) {
    axios.get(gmApi + path, {
        params: data
    }).then((response) => {
        cb ? cb(null, response.data) : null;
    }).catch((error) => {
        cb ? cb(error, null) : null;
    });
}

// const kdtsPost = function (path, data, cb) {
//     axios.post(kdts + path, data).then((response) => {
//         cb ? cb(null, response.data) : null;
//     }).catch((error) => {
//         cb ? cb(error, null) : null;
//     });
// }


export default {
    getGmApi: function () {
        return gmApi;
    },
    order: {
        add: function (data, cb) {
            sendGmGet("/order/add", data, cb);
        },
        gua: {
            get: function (data, cb) {
                sendGmGet("/order/gua/get", data, cb);
            },
            info: function (data, cb) {
                sendGmGet("/order/gua/info", data, cb);
            },
            list: function (data, cb) {
                sendGmGet("/order/gua/list", data, cb);
            }
        }
    },

}