import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Gua from '../views/Gua.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
    },
  },
  {
    path: '/gua',
    name: 'Gua',
    component: Gua,
    meta: {
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.NODE_ENV === 'production' ? "/jdxb/pc/" : process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title ? to.meta.title : "瓜姐姐"}`;
  next();
});

export default router
