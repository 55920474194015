<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  components: {},
  data: function () {
    return {};
  },
  watch: {
    $route(to) {},
  },
  methods: {
    ...mapActions([]),
  },
  mounted: function () {},
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
}
html {
  overflow: -moz-hidden-unscrollable; /*注意！若只打 hidden，chrome 的其它 hidden 会出问题*/
}

/*body {*/
/*	width: calc(100vw + 18px); !*浏览器滚动条的长度大约是 18px*!*/
/*	overflow: auto;*/
/*}*/

#app {
  font-family: "Microsoft Yahei", "微软雅黑", Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
not supported by any browser */
}
</style>

