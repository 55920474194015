import Vue from 'vue'
import Vuex from 'vuex'
import Req from '../req/index'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },

    actions: {
        orderAdd: function (store, [guaid, cb]) {
            let data = {
                guaid: guaid
            }
            Req.order.add(data, cb);
        },
        guaGet: function (store, [orderid, guaid, code, cb]) {
            let data = {
                orderid: orderid,
                guaid: guaid,
                code: code
            }
            Req.order.gua.get(data, cb);
        },
        guaInfoGet: function (store, [guaid, cb]) {
            let data = {
                guaid: guaid
            }
            Req.order.gua.info(data, cb);
        },
        guaListGet: function (store, [page, count, cb]) {
            let data = {
                page: page,
                count: count,
            }
            Req.order.gua.list(data, cb);
        },
    }

})