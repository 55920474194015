<template>
  <div class="home">
    <img class="cg-img" :src="require('../assets/cg.gif')" />
    <div class="title">历史吃瓜：</div>
    <div
      class="item"
      @click="jumpGua(item.guaid)"
      :key="item.guaid"
      v-for="item in list"
    >
      {{ item.title }}
    </div>
    <div class="more-btn" @click="getGuaList" v-if="allCount > page * count">
      加载更多
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      page: 0,
      count: 10,
      allCount: 0,
      list: [],
    };
  },
  methods: {
    ...mapActions(["guaListGet"]),
    jumpGua: function (guaid) {
      let _that = this;
      _that.$router.push({
        path: "/gua?guaid=" + guaid,
      });
    },
    getGuaList: function () {
      let _that = this;
      _that.page++;
      _that.guaListGet([
        _that.page,
        _that.count,
        function (err, data) {
          if (!err && !data.rtn) {
            _that.list = _that.list.concat(data.data.list);
            _that.allCount = data.data.count;
          }
        },
      ]);
    },
  },
  mounted() {
    this.getGuaList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.home {
  text-align: center;
  padding-top: 1px;
  .cg-img {
    margin-top: 40px;
    width: 60px;
  }
  .title {
    width: 90%;
    margin: 0 auto;
    text-align: left;
    font-weight: bolder;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .item {
    background-color: #fafafa;
    width: 84%;
    // height: 30px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 16px;
    padding: 10px;
    text-align: left;
  }

  .more-btn {
    margin-top: 10px;
    font-size: 12px;
  }
}
</style>
