export default {
    getLocalOrderId: function (guaid) {
        let orderid = localStorage.getItem("orderid-" + guaid);
        if (orderid) {
            return orderid;
        } else {
            return null;
        }
    },
    setLocalOrderId: function (guaid, orderid) {
        localStorage.setItem("orderid-" + guaid, orderid);
    }
}